<template>
    <div>
        <div @click="outClick($event)">
            <div v-if="popOpen&&clickClose" @click="changePop" :class="shadeClass" >
                <div class="popContent" @click="outClick">
                    <slot name="popContent"></slot>
                </div>
            </div>

            <div v-if="popOpen&&!clickClose" :class="shadeClass" >
                <div class="popContent" @click="outClick">
                    <slot name="popContent"></slot>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Pop",
        props: [
            'bg',//背景色
            'clickClose',//是否点击空白处关闭
        ],
        data() {
            return {
                shadeStyle: {},
                popOpen: false,
                shadeClass: '',
                time: ''
            }
        },
        mounted() {
            this.shadeStyle = {
                height: window.innerHeight + 'px',
                width: window.innerWidth + 'px',
                background: this.bg
            }
        },
        methods: {
            //调用关闭
            changePop() {
                let _this = this;
                if (this.popOpen) {
                    this.shadeClass = 'shade2';
                    setTimeout(function () {
                        _this.popOpen = false;
                    }, 300);
                } else {
                    this.shadeClass = 'shade';
                    this.popOpen = true;
                }
            },
            //自动关闭
            changePop2() {
                if (this.time) {
                    clearTimeout(this.time);
                }
                let _this = this;
                this.shadeClass = 'shade';
                this.popOpen = true;
                this.time = setTimeout(function () {
                    _this.popOpen = false;
                }, 3000);
            },
            outClick(e) {
                e.stopPropagation();
            }
        }
    }
</script>

<style scoped>
    .shade, .shade2 {
        position: fixed;
        left: 0;
        top: 0;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, .3);
        z-index: 2000;
    }

    .shade {
        animation: bian 0.3s linear both 1;
    }

    .shade2 {
        animation: bian2 0.3s linear both 1;
    }

    .popContent {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    @keyframes bian {
        0% {
            opacity: 0;
        }

        100% {
            opacity: 1;
        }
    }

    @keyframes bian2 {
        0% {
            opacity: 1;
        }

        100% {
            opacity: 0;
        }
    }
</style>